import { uploadFileRequest } from "api/print";
import { useForm } from "hooks/useForm";
import React from "react";
import { types } from "store/Print";
import { StoreContext } from "store/StoreProvider";
import { getAllOrdes } from "store/sagas/Orders";
import { getPrintersFreePrint } from "store/sagas/Print";
import { uploadFile } from "store/sagas/Print";
import { printOrder } from "store/sagas/Print";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";



export const PrintSettings = React.forwardRef((props, ref) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [printers, setPinters] = React.useState([]);
  const [selectedPrinter, setPinter] = React.useState({});
  const [values, handleChange] = useForm({
    copies: 1,
    ColorModel: "Grayscale",
    sides: "one-sided",
    media: "letter",
    printer: "",
  });
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    getPrintersFreePrint().then((res) => {
      setPinters(res);
      if (!!store.printerId) {
        let foundedPrinter = res.find(printer => printer._id === store.printerId);
        if (!!foundedPrinter) {
          setPinter(foundedPrinter);
          handleChange({
            name: "printer",
            value: store.printerId,
          });

        }
      }
    })
  }, [])

  React.useImperativeHandle(ref, () => {
    return {
      getSettings() {
        console.log(values);
      },
    };
  });

  const send = () => {

    if (validations()) {
      Swal.fire({
        title: "¡Todo Listo!",
        text: "¿Deseas imprimir tus documentos en este momento?",
        icon: "info",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "¡Imprimir!",
        confirmButtonColor: "#32D74B",
        cancelButtonColor: "#FF453A",
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          return new Promise((res,rej) => {
             const formData = new FormData();

             let settings = {
               copies: values.copies,
               ColorModel: values.ColorModel,
               sides: values.sides,
               media: values.media,
             };
             formData.append("id", store.user?.id);
             formData.append("settings", JSON.stringify(settings));
             formData.append("printer", values.printer);
             formData.append("selectedDocs", `${JSON.stringify(props.files)}`);
             props.files.forEach((doc) => {
               formData.append("file-to-upload", doc);
             });

             uploadFile(formData).then((resu) => {
               let obj = {
                 order_id: resu.id,
                 selected: resu.files,
               };
               fetch(`${process.env.REACT_APP_IP_BASE}/api/print-pwa`, {
                 method: "POST",
                 mode: "cors",
                 cache: "no-cache",
                 credentials: "same-origin",
                 headers: {
                   "Content-Type": "application/json",
                 },
                 redirect: "follow",
                 referrerPolicy: "no-referrer",
                 body: JSON.stringify(obj),
               })
                 .then((response) => {
                   if (!response.ok) {
                     throw new Error(response.statusText);
                   }
                   return res(response.json());
                 })
                 .catch((error) => {
                   Swal.showValidationMessage(`Request failed: ${error}`);
                 });
             });
          })

        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: `Completado`,
            icon: "success",
            text: "No olvides retirar tus documentos",
          }).then((response) => {
            getAllOrdes({ id: store.user.id }).then((result) => {
              dispatch({ type: types.getOrders, payload: result });
            });
            props.cancelCallback(false);
          });
        }
      });
    }

  };

  

  const sendWithRequest = () => {
    if(validations()) {
    Swal.fire({
      title: t("everythinggood"),
      text: t("textconfirmprint"),
      icon: "info",
      showCancelButton: true,
      confirmButtonText: t("print"),
        confirmButtonColor: "#32D74B",
        cancelButtonColor: "#FF453A",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        Swal.showLoading(Swal.getCancelButton())
        try {
          const githubUrl = `${process.env.REACT_APP_IP_BASE}/api/request-pwa`
          const response = await fetch(githubUrl,{
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({printere: values.printer})
          });
          if (!response.ok) {
            return Swal.showValidationMessage(`
              ${JSON.stringify(await response.json())}`);
          }
          let response2 = await response.json();
          return response2;
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: true
      });
      if (result.isDismissed) {
        swalWithBootstrapButtons.fire({
          title: t("cancelled"),
          text: t("ordernotcreated"),
          icon: "error"
        });
      }
      if (result.value.result) {
        // Do the real print after this response
          return new Promise((res,rej) => {
            const formData = new FormData();

            let settings = {
              copies: values.copies,
              ColorModel: values.ColorModel,
              sides: values.sides,
              media: values.media,
            };
            formData.append("id", store.user?.id);
            formData.append("settings", JSON.stringify(settings));
            formData.append("printer", values.printer);
            formData.append("selectedDocs", `${JSON.stringify(props.files)}`);

            props.files.forEach((doc) => {
              formData.append("file-to-upload", doc);
            });

            uploadFile(formData).then((resu) => {
              let obj = {
                order_id: resu.id,
                selected: resu.files,
              };

              //api print test
              fetch(`${process.env.REACT_APP_IP_BASE}/api/print-pwa`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                  "Content-Type": "application/json",
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify(obj),
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error(response.statusText);
                  }
                  Swal.fire({
                    title: t("completed"),
                      icon: "success",
                      text: t("donotforgetfiles"),
                  });
                  return res(response.json());
                })
                .catch((error) => {
                  //Swal.showValidationMessage(`Request failed: ${error}`);
                  return rej(error.message);
                });
            });
         })
      } else {
        swalWithBootstrapButtons.fire({
          title: t("cancelled"),
          text: t("ordernotcreated"),
          icon: "error"

        });
      }
    });
  }
  }
 


  const validations = () => {
    let res = true
    if (!values.printer) {
      Swal.fire({
        title: "Un momento…",
        text: "Debes seleccionar una impresora primero",
        icon: "warning"
      })

      res = false
    }
    return res;
  }


  return (
    <div className="flex min-h-[100%] flex-col justify-between p-2">
      <div className="flex h-[60vh] flex-col justify-between pl-2">
        <div className="flex flex-row justify-between ">
          <div className="" style={title}>
           {t("print")}
          </div>
          <div className="" style={title}>
            {props.nfiles} {t("file")}{props.nfiles > 1 ? "s" : ""} {t("toprint")}
          </div>
        </div>

        <div className="flex flex-row justify-between ">
          <div className="flex flex-1 items-center justify-start" style={title}>
            {t("printer")}
          </div>
          <div className="flex flex-1 items-center justify-end">
            <div className="relative inline-block min-w-full">
              <select
                className="focus:shadow-outline block w-full appearance-none rounded border border-gray-400 bg-white px-4 py-2 pr-8 leading-tight shadow hover:border-gray-500 focus:outline-none"
                onChange={(e) => {
                  let value = e.target.value;
                  let printer = printers.find(({ _id }) => _id === value);
                  setPinter(printer);
                  handleChange({
                    name: "printer",
                    value,
                  });
                }}
                value={values.printer}
              >
                <option value={""} defaultValue>
                {t("printer")}
                </option>
                {printers.map((item, key) => (
                  <option key={key} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between ">
          <div className="flex flex-1 items-center justify-start" style={title}>
            # {t("copies")}
          </div>
          <div className="flex flex-1 items-center justify-end">
            <div className="relative inline-block min-w-full">
              <select
                className="focus:shadow-outline block w-full appearance-none rounded border border-gray-400 bg-white px-4 py-2 pr-8 leading-tight shadow hover:border-gray-500 focus:outline-none"
                onChange={(e) =>
                  handleChange({
                    name: "copies",
                    value: Number(e.target.value),
                  })
                }
                value={values.copies}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between ">
          <div className="flex flex-1 items-center justify-start" style={title}>
            Color
          </div>
          <div className="flex flex-1 items-center justify-end">
            <div className="relative inline-block min-w-full">
              <select
                className="focus:shadow-outline block w-full appearance-none rounded border border-gray-400 bg-white px-4 py-2 pr-8 leading-tight shadow hover:border-gray-500 focus:outline-none"
                onChange={(e) => {
                  handleChange({ name: "ColorModel", value: e.target.value });
                  props.setColor(e.target.value);
                }}
                value={values.ColorModel}
              >
                {!!selectedPrinter && !!Object.keys(selectedPrinter).length &&
                  selectedPrinter.color_model.map((item, key) => {
                    if (item === "Gray") {
                      return (
                        <option value={"Grayscale"} key={key} selected>
                          {t("black&white")}
                        </option>
                      );
                    } else {
                      return (
                        <option value={item} key={key}>
                          {item}
                        </option>
                      );
                    }
                  })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between ">
          <div className="flex flex-1 items-center justify-start" style={title}>
            {t("sides")}
          </div>
          <div className="flex flex-1 items-center justify-end">
            <div className="relative inline-block min-w-full">
              <select
                className="focus:shadow-outline block w-full appearance-none rounded border border-gray-400 bg-white px-4 py-2 pr-8 leading-tight shadow hover:border-gray-500 focus:outline-none"
                onChange={(e) =>
                  handleChange({ name: "sides", value: e.target.value })
                }
                value={values.sides}
              >
                <option value="one-sided">1 Cara</option>
                <option value="two-sided-long-edge">Doble Cara</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-row justify-between ">
          <div className="flex flex-1 items-center justify-start" style={title}>
            Tamaño del papel
          </div>
          <div className="flex flex-1 items-center justify-end">
            <div className="relative inline-block min-w-full">
              <select
                className="focus:shadow-outline block w-full appearance-none rounded border border-gray-400 bg-white px-4 py-2 pr-8 leading-tight shadow hover:border-gray-500 focus:outline-none"
                onChange={(e) =>
                  handleChange({ name: "media", value: e.target.value })
                }
                value={values.media}
              >
                <option value="letter">Carta</option>
                <option value="legal">Oficio</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="flex flex-row justify-between ">
          <div className="" style={title}>
            Añadir a favorito
          </div>
          <div className="">
            <input type="checkbox" name="" id="" />
          </div>
        </div> */}
      </div>

      <div className="flex flex-row justify-between mt-12 sm:mt-0">
        <div className="mx-1 flex-1 ">
          <button
            className="w-full rounded-[10px] bg-[#FF453A] px-1 py-2 text-white"
            onClick={() => props.cancelCallback(false)}
          >
            {t("cancel")}
          </button>
        </div>
        {!!props.nfiles && (
          <div className="mx-1 flex-1">
            <button
              onClick={sendWithRequest}
              className="w-full rounded-[10px] bg-[#0F81FE] px-1 py-2 text-white"
            >
              {t("print")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
});

const title = {
  color: "#202020",
  fontFamily: "DM Sans",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "24px,",
};
