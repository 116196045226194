import InputField from "components/fields/InputField";
import { useState, useContext } from "react";
import { StoreContext } from "store/StoreProvider";
import logo from "assets/img/printgo/logo_black.svg";
import { types } from "../../store/Auth";
import { loginUserFreePrint, validateCode } from "store/sagas/Auth";
import Swal from "sweetalert2";
import Phoneinput from "./components/SelectPhoneCountry"
import { useTranslation } from "react-i18next";
import LanguageSelector from "./components/language-selector"
import { MyDialog } from "./components/modalLanguage2";
import LanguageSwitchToggle from "./components/language-switch";

export default function SignIn() {
  const [value, setValue] = useState("");
  const [code, setCode] = useState("");
  const [validate, setValidate] = useState(false);
  const [store, dispatch] = useContext(StoreContext);
  const { t, i18n } = useTranslation();

  //comentario test
  const sendNude = () => {
    let obj = { user_data: value };
    loginUserFreePrint(obj)
      .then(async (res) => {
        let text = await res.text();
        if (text === `{"message":"Ingrese email valido o numero telefonico"}`) {
          Swal.fire("Un momento...", "Ingrese email valido", "warning");
        } else if (
          text ===
          `Su correo electronico no está registrado, porfavor contacte con soporte`
        ) {
          Swal.fire({
            title: "Un momento...",
            text: "Su correo electrónico no está registrado, por favor contacte con nuestro soporte para ayuda.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#32D74B",
            cancelButtonColor: "#0F81FE",
            confirmButtonText: "SOPORTE",
            cancelButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              window.open("https://wa.me/573057232113");
            }
          });

        } else if (text === `"User NEW: code set by phone SMS"`) {
          Swal.fire({
            title:  `${t("codesent1")}`,
            text: `${t("codesent")}`,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            setValidate(true);
          });
        } else if (text === `"User exist: code set by phone SMS"`) {
          Swal.fire({
            title: `${t("codesent1")}`,
            text: `${t("codesent")}`,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            setValidate(true);
          });
        } else {
          Swal.fire(`${t("error")}`, `${t("error1")}`, "warning");
        }
      })
      .catch((e) => {
        console.log(e);
        // alert(e);
      });
  };

  const validateNude = () => {
    validateCode({ user_data: value, validation_code: code })
      .then((res) => {
        if (!!res.message) {
          Swal.fire(`${t("validationerror1")}`, `${t("validationerror")}`, "warning");
        } else {
          dispatch({ type: types.authLogin, payload: res.data });
        }
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      });
  };

  const maxNumber = (e) => {
    // var number = e.target.value;
    // const regexSoloNumeros = /^\d+$/;
    // if(!regexSoloNumeros.test(number) && number != "") return;
    setValue(e);
  }
  const maxNumberCode = (e) => {
    var number = e.target.value;
    const regexSoloNumeros = /^\d+$/;
    console.log(number);
    if(!regexSoloNumeros.test(number) && number != "") return;
    if (number.length <= 4) setCode(number);
  }

  return (
    <div>
      <div className="mb-16 mt-0 flex h-full w-full items-center justify-center px-2 sm:mt-16 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
        {/* Sign in section */}
        <div className="mt-[0] w-full max-w-full flex-col items-center sm:mt-[10vh] md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <div className="flex justify-between">
              <img
                className="block w-[40%] sm:hidden sm:w-[60%] mb-8"
                src={logo}
                alt="PrintGo"
              />
              <div className="block md:hidden">
              <LanguageSwitchToggle/>
              </div>
            </div>
          {/* <LanguageSelector/> */}
          <div className="flex justify-between">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            { t("welcome") }
          </h4>
           <div className="hidden md:block mt-1">
                <LanguageSwitchToggle/>
           </div>
                
              </div>
          
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
              if (!!validate) {
                validateNude();
              } else {
                sendNude();
              }
            }}
          >
            {!!validate ? (
              <>
                <p className="mb-9 ml-1 text-base text-gray-600">
                  {t("codesent")}
                </p>

                {/* VALIDATE VODE */}
                <InputField
                  extra="mb-3"
                  extraCode="text-center"
                  label={t("labelvalidation")}
                  placeholder="****"
                  type="number"
                  min="1" 
                  max="9999"
                  value={code}
                  onChange={maxNumberCode}
                />
              </>
            ) : (
              <>
                <p className="mb-9 ml-1 text-base text-gray-600">
                    { t("join") }
                </p>

                {/* Email */}
                {/* <InputField
                  variant="auth"
                  extra="mb-3"
                  require={true}
                  label="Teléfono *"
                  placeholder="310-555-9999"
                  id="email"
                  type="number"
                  value={value}
                  min="1" 
                  max="9999999999"
                  onChange={maxNumber}
                /> */}
                <Phoneinput
                onChange={maxNumber}
                value={value}
                />

              </>
            )}

            {/* Checkbox */}
            <div className="mb-4 flex items-center justify-between px-2">
              {!!validate && (
                <a
                  className="cursor-pointer text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                  onClick={() => setValidate(false)}
                >
                  {t("sendagain")}
                </a>
              )}
            </div>
            <button
              // onClick={(e) => {
              //   e.preventDefault();
              //   if (!!validate) {
              //     validateNude();
              //   } else {
              //     sendNude();
              //   }
              // }}
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              {!!validate ? `${t("buttonvalidatecode")}` : `${t("enter")}`}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
