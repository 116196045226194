import React, { useEffect } from "react";
import { MdModeEditOutline } from "react-icons/md";
import image1 from "assets/img/profile/image1.png";
import image2 from "assets/img/profile/image2.png";
import image3 from "assets/img/profile/image3.png";
import Card from "components/card";
import { useForm } from "hooks/useForm";
import { updateProfile } from "store/sagas/Employes";
import Swal from "sweetalert2";
import { StoreContext } from "store/StoreProvider";
import { deleteEmployes } from "store/sagas/Employes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import languagesArray from "translations/countries/countries.json"
import * as moment from "moment";

const Project = (props) => {
  const [update, setUpdate] = React.useState(false);
  const [store] = React.useContext(StoreContext);
  const { t } = useTranslation('translation');

  let form = {
    name: props?.name || "",
    phone: props?.phone || "",
    email: props?.email || "",
    nPaper: props?.nPaper || "",
    user_type: props?.user_type || "",
    nationality: props?.nationality || "",
    age: props?.age || "",
    occupation: props?.occupation || "",
    address: props?.address || "",
    gender: props?.gender || ""
  };

  const [values, handleChange] = useForm(form);

  const handleUpd = (obj) => {
    if (!update) {
      setUpdate(true);
    }
    handleChange(obj);
  };

  useEffect(() => {
    console.log("Updated values:", values);
  }, [values]);

  const handleSubmit = () => {
    let obj
    if (store.user.user_type === 3) {
      obj = {
        _id: props._id,
        name: values.name,
      };
    } else {
      obj = {
        _id: props._id,
        name: values.name,
        email: values.email,
        nPaper: values.nPaper,
        user_type: Number(values.user_type),
        nationality: values.nationality,
        address: values.address,
        age: values.age,
        occupation: values.occupation,
        gender: values.gender,
      };
    }

    console.log(obj);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let emailTest = emailRegex.test(obj.email);

    if(!emailTest) {
      Swal.fire({
        icon: "error",
        title: `${t("validationerror1")}`,
        text: `${t("invalidemail")}`,
      });
      return;
    } else if (!obj.name){ 
      Swal.fire({
        icon: "error",
        title: `${t("validationerror1")}`,
        text: `${t("invalidname")}`,
      });
      return;
    }

    updateProfile(obj).then(() => {
      Swal.fire({
        title: `${t("verywell")}`,
        text: `${t("userupdated")}`,
        icon: "success",
        confirmButtonText: "Ok",
      }).then((result) => {
        setUpdate(false);
        props.getUser();
      });
    });
  };

  const handleStatus = () => {
    let obj = {
      _id: props._id,
      status: !props.status,
    };
    updateProfile(obj).then(() => {
      Swal.fire({
        title: `${t("verywell")}`,
        text: `${t("userupdated")}`,
        icon: "success",
        confirmButtonText: "Ok",
      }).then((result) => {
        setUpdate(false);
        props.getUser();
      });
    });
  };

  const navigate = useNavigate();

  const handleDelte = () => {

    Swal.fire({
      title: "Eliminar",
      text: "¿Quieres eliminar este usuario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ACEPTAR",
      cancelButtonText: "CANCELAR",
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {
          _id: props._id,
        };
        deleteEmployes(obj).then(() => {
          Swal.fire("¡Elminado!", "", "success");
          navigate(`/admin/users/`);
        });
      }
    });
  };



 const actualAge = () => {

 }

  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-8 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {t("perfildata")}
        </h4>
      </div>
      <form className="w-full ">
        <div className="mb-6 md:flex md:items-center">
          <div className="md:w-1/3">
            <label
              className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
              htmlFor="inline-full-name"
            >
              {t("name")}
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              defaultValue={props?.name}
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none"
              id="inline-full-name"
              onChange={(a) =>
                handleUpd({ name: "name", value: a.target.value })
              }
              value={values.name}
            />
          </div>
        </div>
        <div className="mb-6 md:flex md:items-center">
          <div className="md:w-1/3">
            <label
              className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
              htmlFor="inline-phone"
            >
              {t("phone")}
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              defaultValue={values.phone}
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none"
              id="inline-phone"
              type='readonly'
              // onChange={(e) =>
              //   handleUpd({ name: "phone", value: e.target.value })
              // }
              value={values.phone}
            />
          </div>
        </div>
        <div className="mb-6 md:flex md:items-center">
          <div className="md:w-1/3">
            <label
              className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
              htmlFor="inline-email"
            >
              Email
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              defaultValue={props?.phone}
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none"
              id="inline-email"
              readOnly={store.user.user_type === 3}
              type="email"
              onChange={(e) =>
                handleUpd({ name: "email", value: e.target.value })
              }
              value={
                values.email
              }
            />
          </div>
        </div>
        <div className="mb-6 md:flex md:items-center">
          <div className="md:w-1/3">
            <label
              className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
              htmlFor="inline-full-name"
            >
              {t("address")}
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              defaultValue={values.address}
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none"
              id="inline-full-name " onChange={(i) =>
                handleUpd({ name: "address", value: i.target.value})
              }
              value={values.address}
            />
          </div>
        </div>   
        <div className="mb-6 md:flex md:items-center">
          <div className="md:w-1/3">
            <label
              className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
              htmlFor="inline-full-name"
            >
              {t("occupation")}
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              defaultValue={values.occupation}
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none"
              id="inline-full-name " onChange={(o) =>
                handleUpd({ name: "occupation", value: o.target.value })
              }
              value={values.occupation}
            />
          </div>
        </div>  
        <div className="mb-6 md:flex md:items-center">
          <div className="md:w-1/3">
            <label
              className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
              htmlFor="inline-full-name"
            >
              {t("country")}
            </label>
          </div>
          <div className="md:w-2/3">
          <select className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none" onChange={(u) => handleUpd({ name: "nationality", value: u.target.value})} value={values.country}>
              <option ></option>
              <>
              {languagesArray.countries.map((idioma) => {
                  return (<option value={idioma.name}>{idioma.name}</option>);
                })}
                </>
            </select>
          </div>
        </div> 
        <div className="mb-6 md:flex md:items-center">
          <div className="md:w-1/3">
            <label
              className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
              htmlFor="inline-full-name"
            >
                {t("age")}
            </label>
          </div>
          <div className="md:w-2/3">
            <input className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none"
               type="date" id="start" name="trip-start" onChange={(ae) => handleUpd({ name: "age", value: ae.target.value})} value={values.age} min="1940-01-01" max={moment().format("YYYY-MM-DD")}
            />
          </div>
        </div> 
        <div className="mb-6 md:flex md:items-center">
          <div className="md:w-1/3">
            <label
              className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
              htmlFor="inline-full-name"
            >
              {t("gender")}
            </label>
          </div>
          <div className="md:w-2/3">
          <select className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none" onChange={(ai) => handleUpd({ name: "gender", value: ai.target.value})} 
          value={values.gender}>
              <option>{t("selectgender")}</option>
              <option value="Male">{t("male")}</option>
              <option value="Female">{t("female")}</option>
            </select>
          </div>
        </div> 
      </form>

      <div className="rtl text-neutral-800 h w-full justify-items-end  text-end text-sm font-bold leading-tight text-opacity-20">
        <button
          className={`focus:shadow-outline rounded px-4 py-2 font-bold text-white shadow focus:outline-none ${
            !update
              ? "bg-gray-400 hover:bg-gray-500"
              : "bg-blue-500 hover:bg-blue-400"
          }`}
          disabled={!update}
          onClick={handleSubmit}
          type="button"
        >
          {t("Save")}
        </button>
      </div>
    </Card>
  );
};

export default Project;
