import { StatusIcon } from "components/icons/StatusIcon";
import { Link } from "react-router-dom";
import ColumnsTable from "views/admin/tables/components/ColumnsTable";
import AddIcon from "assets/svg/add.svg";
import OrderIcon from "assets/svg/order_icon.svg";
import { getAllOrdes } from "store/sagas/Orders";
import React from "react";
import { StoreContext } from "store/StoreProvider";
import Card from "components/card";
import { gedDivisions } from "store/sagas/Divisions";
import { types } from "store/Print";
import { EmptyList } from "components/EmptyList";
import { getOrdersByUserFreePrint } from "store/sagas/Orders";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import "moment/locale/es";
import Swal from "sweetalert2";
import { useState } from "react";
import { Dialog } from "@headlessui/react";



const ModalOrderDetails = (props) => {
  const { t } = useTranslation("translation");
  const settingsData = {
    Grayscale: `${t("grayscale")}`,
    Gray: `${t("grayscale")}`,
    "one-sided": `${t("onesided")}`,
    "two-sided-long-edge": `${t("twosided")}`,
    letter: `${t("letter")}`,
    legal: {}, //oficio
    portrait: "Vertical",
    landscape: "Horizontal",
  };



  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  function validateSettings(settings) {
    let html = [];
    console.log(settings);
    if (!settings) return html.push(`No hay configuraciones`);
    if (settings?.media) html.push(`${t("media")}: ${settingsData[settings?.media]}`);
    if (settings?.copies) html.push(`${t("copies")}: ${settings?.copies}`);
    if (settings?.ColorModel) html.push(`Color: ${settingsData[settings?.ColorModel]}`);
    if (settings?.sides) html.push(`${t("sides")}: ${settingsData[settings?.sides]}`);
    
    return html;
  }

  if (!props.item) {
    return;
  }
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div
        className="fixed inset-0 bg-black/30 bg-[#212529] opacity-70"
        aria-hidden="true"
      />

      {/* Full-screen scrollable container */}
      <div className="fixed inset-0 w-screen overflow-y-auto">
        {/* Container to center the panel */}
        <div className="flex min-h-full items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="w-full rounded bg-white px-6 py-3 ">
            <Dialog.Title
              as="h3"
              className="text-lg text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400 text-center px-8 py-4 rounded" 
            >
              {t("orderdetails")}
            </Dialog.Title>
            {console.log(props.item)}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
              <table className=" w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-4 text-left">
                      {t("filename")}
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      {t("numpages")}
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      {t("createdat")}
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      {t("filesize")}
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      {t("printconfig")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!!props.item.files && props.item.files.length === 1 ? (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="text-left px-4 py-4 text-gray-800 font-semibold">{props.item.files[0].originalname}</td>
                      <td className="text-center px-4 py-4 text-gray-800">{props.item.files[0].numpages}</td>
                      <td className="text-center px-4 py-4 ">{moment(props.item.createdAt).format("LL")}</td>
                      <td className="text-center px-4 py-4 ">{bytesToSize(props.item.files[0].size)}</td>
                      <>
                        {
                          <td className="px-4 py-4">
                            {!!props.item.settings.length ? (
                              validateSettings(
                                props.item.settings[0]
                              ).map((setting) => <p className="text-left text-gray-800">{setting}</p>)
                            ) : (
                              <p className="text-center text-gray-700">{t("noconfig")}</p>
                            )}
                          </td>
                        }
                      </>
                    </tr>
                  ) : (
                    <>
                      {!!props.item.files &&
                        props.item.files.map((file) => (
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td className="px-6 py-4 text-left text-gray-800 font-semibold">{file.originalname}</td>
                            <td className="px-6 py-4 text-center text-gray-800">{file.numpages}</td>
                            <td className="px-6 py-4 text-center ">
                              {moment(file.createdAt).format("LL")}
                            </td>
                            <td className="px-6 py-4 text-center">
                              {bytesToSize(file.size)}
                            </td>
                            {!!props.item.settings.length ? (
                              validateSettings(
                                props.item.settings[0]
                              ).map((setting) => <p className="text-left text-gray-800">{setting}</p>)
                            ) : (
                              <p className="text-center">{t("noconfig")}</p>
                            )}
                          </tr>
                        ))}
                    </>
                  )}
                  
                </tbody>
                
              </table>
              <div className="flex justify-center">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-4" onClick={() => props.setIsOpen(false)} >
                    Ok
                </button>     
              </div>          

            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export const OrdesList = () => {
  const [store, dispatch] = React.useContext(StoreContext);
  const { t } = useTranslation("translation");
  let [isOpen, setIsOpen] = useState(false);
  let [data, setData] = useState(false);

  const get = (id) => {
    getOrdersByUserFreePrint({ id: store.user.id }).then((result) => {
      dispatch({ type: types.getOrders, payload: result });
    });
  };

  // `
  //           <style type="text/css">
  //           .flex-container {
  //             display: flex;
  //             flex-wrap: wrap;
  //           }
  //           </style>
  //           <div id="modal" class="flex-container" style="">
  //             <div class="text-xl">
  //               <h1>${item.files[0].originalname}</h1>
  //               <ul>
  //                 <li>Nombre del archivo: <span id="nombreArchivo">${item.files[0].originalname}</span></li>
  //                 <li>Numero de páginas: <span id="tamanioArchivo">${item.files[0].numpages}</span></li>
  //                 <li>Numero de archivos <span id="creador">${item.files.length}</span></li>
  //                 <li>Fecha de creación: <span id="fechaCreacion">${item.created_at}</span></li>
  //                 <li>Tama;o de archivo: <span id="size">${filesize}</span></li>
  //                 <li>Printer id: <span id="sdaa">${item.printer_id}</span></li>
  //                 <li>Settings: <span id="settings">${item.settings[0]?.ColorModel}</span></li>
  //               </ul>
  //             </div>
  //           </div>
  //         `

  // opcion 2:

  // <style type="text/css">
  //               .table {
  //                 width:100%;
  //               }
  //               tr {

  //               }
  //               </style>

  //     <div class="card">
  //     <div class="card-body">

  //         <div class="table-responsive">
  //             <table class="table mb-0">
  //                 <thead>
  //                     <tr>
  //                         <th>Numero de archivos</th>
  //                         <th>Numero de páginas</th>
  // <th>Fecha de creación</th>
  // <th>Tamaño de archivo</th>
  // <th>Printer</th>
  // <th>Settings</th>
  //                     </tr>
  //                 </thead>
  //                 <tbody>
  //                     <tr>
  //                         <th scope="row">1</th>
  //                         <td>Mark</td>
  //                         <td>Otto</td>
  //                         <td>@mdo</td>
  //                     </tr>
  //                     <tr>
  //                         <th scope="row">2</th>
  //                         <td>Jacob</td>
  //                         <td>Thornton</td>
  //                         <td>@fat</td>
  //                     </tr>

  //                 </tbody>
  //             </table>
  //         </div>

  //     </div>
  // </div>`

  const showModal = (item, bytesToSize) => {
    console.log(item);

    const renderFiles = () => {
      const validateSettings = (settings) => {
        let ctrl = "";
        if (!settings) {
          return `No hay configuraciones`;
        }
        if (settings?.copies) {
          ctrl += ` \n<p>Copias:${settings?.copies}</p> \n`;
        }
        if (settings?.ColorModel) {
          ctrl += `\n<p>Color:${settings?.ColorModel}</p>\n`;
        }
        if (settings?.sides) {
          ctrl += `<p>Caras: ${settings?.sides}</p> \n`;
        }
        if (settings?.media) {
          ctrl += `\n<p>Tipo: ${settings?.media}</p> \n`;
        }
        return ctrl;
      };
      if (item.files.length == 1) {
        return `<tr> 
                      <td>${item.files[0].originalname}</td> 
                      <td>${item.files[0].numpages}</td>
                      <td>${moment(item?.created_at).format("LL")}</td>
                      <td>${bytesToSize(item.files[0].size)}</td>
                      <td>${item.printer_id}</td>
                      <td>${validateSettings(item.settings[0])}</td>
                  </tr>`;
      } else {
        let random = "";
        item.files.forEach((file) => {
          random += `<tr>
                      <td>${file.originalname}</td>
                      <td>${file.numpages}</td>
                      <td>${moment(item?.created_at).format("LL")}</td>
                      <td>${bytesToSize(file.size)}</td>
                      <td>${item.printer_id}</td>
                      <td>${validateSettings(item.settings[0])}</td>
                    </tr>`;
        });
        return random;
      }
    };

    // )))
    // Swal.fire({
    //   html: `<style>
    //   .details {
    //       overflow: auto;
    //       width: 100%;
    //   }

    //   .details table {
    //       border: 1px dotted #dedede;
    //       height: 100%;
    //       width: 100%;
    //       table-layout: fixed;
    //       border-collapse: collapse;
    //       border-spacing: 0px;
    //       text-align: center;
    //   }

    //   .details caption {
    //       caption-side: top;
    //       text-align: center;
    //   }

    //   .details th {
    //       border: 1px dotted #dedede;
    //       background-color: #F5F5F5;
    //       color: #000000;
    //       padding: 5px;
    //   }

    //   .details td {
    //       border: 1px dotted #dedede;
    //       background-color: #ffffff;
    //       color: #000000;
    //       padding: 5px;
    //   }
    //   </style>
    //   <div class="details" role="region" tabindex="0">
    //   <table>
    //       <caption>
    //           <h1><b>Orden con ${item.files.length} archivos.</b></h1>
    //       </caption>
    //       <thead>
    //           <tr>
    //               <th>Nombre del archivo</th>
    //               <th>Numero de páginas<br></th>
    //               <th>Fecha de creación</th>
    //               <th>Tamaño de archivo</th>
    //               <th>Impresora</th>
    //               <th>Configuración</th>
    //           </tr>
    //       </thead>
    //       <tbody>
    //       ${renderFiles()}
    //       </tbody>
    //   </table>
    //   </div>`,
    //   width: "80%",
    //   grow: true,
    // });
  };

  React.useEffect(() => get(), []);

  return (
    <>
      <ModalOrderDetails isOpen={isOpen} setIsOpen={setIsOpen} item={data} />
      <Card extra={"w-full pb-10 p-4 h-full"}>
        <div className="lg:block headquarters-container flex w-full">
          <div className="card w-full">
            <div className="card-header">
              <h2 className="title">{t("Ordenes")}</h2>
              <div className="float-right flex ">
                <Link to={"/admin/dashboard"}>
                  <button className="button">
                    <img src={AddIcon} alt="" className="h-[24px] w-[24px]" />
                  </button>
                </Link>
              </div>
            </div>
            <div className="h-full overflow-y-auto">
              <div className="headquarters-list">
                {!!store.orders.length ? (
                  <>
                    {store.orders.map((item, key) => (
                      <button
                        key={item._id}
                        onClick={() => {
                          setData(item);
                          setIsOpen(true);
                        }}
                      >
                        <div className="headquarters-item" key={key}>
                          <div className="item-left flex">
                            <img
                              src={OrderIcon}
                              alt=""
                              className="h-[46px] w-[46px] rounded-full"
                            />
                            <div className="item-overlap ml-2">
                              <div className="title">
                                {item?.files[0].originalname}
                              </div>
                              <div className="subtitle">
                                {moment(item?.created_at).format("LL")}
                              </div>
                            </div>
                          </div>
                          <div className="item-right flex items-center">
                            <div className="boton">
                              <div className="overlap-group">
                                {/* <Link to={`/admin/divisions/show/${item._id}`}>
                            <button className="button button-primary">
                              <span className="text-white">Ver</span>
                            </button>
                          </Link> */}
                              </div>
                            </div>
                            <div className="status-container ml-2">
                              <StatusIcon />
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  <>
                    <EmptyList text={t("noordersyet")} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
