import React, { useRef, useState } from "react";
import {APIProvider, Map, AdvancedMarker, Pin, Marker, useAdvancedMarkerRef, InfoWindow} from '@vis.gl/react-google-maps';
import printerImg from "assets/img/printer_location.png";

export const MapView = () => {
  const [printers, setPrinters] = useState([]);
  const [currentPosition, setCurrentPosition] = useState({});
  const [infowindowShown, setInfowindowShown] = useState([]);
  const [zoom, setZoom] = useState(12);
  //const [markerRef, marker] = useAdvancedMarkerRef(); 

  const markerRefs = useRef([]);

  React.useEffect(() => {
    // Function to get the current position
    const getCurrentPosition = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCurrentPosition({ lat: latitude, lng: longitude });
          },
          (error) => {
            console.error('Error getting current position:', error);
            setCurrentPosition({lat: 4.681429949952516, lng: -74.08147794979133});
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        setCurrentPosition({lat: 4.681429949952516, lng: -74.08147794979133});
      }
    };

    // Call the function to get the current position
    getCurrentPosition();
  }, []);

  const getPrinters = () => {
    const URL = `${process.env.REACT_APP_URL_BASE}/get-printers-free-print`
    fetch(URL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer"
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setPrinters(data)
        setInfowindowShown(new Array(data.length).fill(false));
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      });
  };
  
  const toggleInfoWindow = (index) => {
    setInfowindowShown(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
    const marker = markerRefs.current[index];
    console.log(markerRefs.current[index]);
  if (marker) {
    const position = printers[index];
    const { lat, lng } = position;
    setCurrentPosition({lat: parseFloat(lat), lng: parseFloat(lng)})
      //setZoom(15);
  }
  };

  React.useEffect(() => getPrinters(), []);

  return (
    <>
      <div className=" mt-2 rounded-3xl bg-white p-1 ">
      <APIProvider apiKey={process.env.REACT_APP_API_KEY_GOOGLE}>
        {!!Object.keys(currentPosition).length && <Map
            style={{height: '100vh'}}
            defaultCenter={currentPosition}
            defaultZoom={12}
            //zoom={zoom}
            gestureHandling={'greedy'}
            disableDefaultUI={false}
            mapId={process.env.REACT_APP_MAP_ID}
          > 
          {currentPosition && (
            <Marker position={currentPosition} icon={{ url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" }} />
          )}
            {printers.length > 0 && printers.map((printer, index) => (
            <React.Fragment key={printer._id}>
              <AdvancedMarker
                ref={el => (markerRefs.current[index] = el)}
                position={{ lat: parseFloat(printer.lat), lng: parseFloat(printer.lng) }}
                onClick={() => toggleInfoWindow(index)}
              >
                <img src={printerImg} alt={`Printer ${index}`} style={{ width: 40, height: 50 }} />
              </AdvancedMarker>
              {infowindowShown[index] && (
                <InfoWindow
                  anchor={markerRefs.current[index]}
                  visible={infowindowShown[index]}
                  onCloseClick={() => toggleInfoWindow(index)}
                >
                  <h2>{`Name: ${printer.name}`}</h2>
                  <p>{`adress: ${printer.address}`}</p>
                  <p>{`model: ${printer.model}`}</p>
                  <p>{`brand: ${printer.brand}`}</p>
                </InfoWindow>
              )}
            </React.Fragment>
          ))}
            
          </Map>}
         
        
        </APIProvider>
      </div>
    </>
  );
};
