import React, { useCallback, useRef, useEffect, Fragment } from "react";
import { upload } from "@testing-library/user-event/dist/upload";
import { MdUploadFile } from "react-icons/md";
import upload1 from "assets/svg/uploadIcon.svg";
import "../uploadview/css/uploadview.css";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";

import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import logo from "assets/img/printgo/logo_black.svg";
import { pdfjs } from "react-pdf";
import { AiOutlineCheck, AiOutlineDelete } from "react-icons/ai";
import { PrintSettings } from "./PrintSettings";
import Swal from "sweetalert2";
import Storage from "views/admin/profile/components/Storage";
import { StoreContext } from "store/StoreProvider";
import { types } from "store/Print";
import { useSearchParams } from "react-router-dom";
import { MyDialog } from "views/auth/components/modalLanguage2";
import AdModal from "views/auth/components/Ads_modal";
import RulesModal from "views/auth/components/rules_modal";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const ListItem = (props) => {
  const [isShowing, setIsShowing] = React.useState(false);
  return (
    <li
      onClick={(e) => {
        e.stopPropagation();
        if (props.preview !== props.file) {
          props.setPreview("");
          setTimeout(() => props.setPreview(props.file), 100);
        }
      }}
    >
      <div className="file-item flex justify-between border-b-2 bg-white px-2 py-4 hover:cursor-pointer hover:bg-gray-50">
        <span className="text-sm sm:text-md ">{props.file.name}</span>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.removeFileList(props.file);
          }}
          onMouseEnter={() => setIsShowing(true)}
          onMouseLeave={() => setIsShowing(false)}
        >
          {!isShowing && (
            <AiOutlineCheck className="h-6 w-6 " color="#32D74B" />
          )}
          <Transition show={isShowing}>
            <AiOutlineDelete className="h-6 w-6 " color="#FF453A" />
          </Transition>
        </button>
      </div>
    </li>
  );
};

const Modal = (props) => {
  const [preview, setPreview] = React.useState(props.pdfFile[0] || "");
  const [files, setFiles] = React.useState(props.pdfFile || []);
  const [color, setColor] = React.useState("Grayscale");
  const cancelButtonRef = useRef(null);
  const { t, i18n } = useTranslation();

  const removeFileList = (file) => {
    let newFiles = files.filter((item) => file.name !== item.name);
    setFiles(newFiles);
    if (!!newFiles.length) {
      setPreview("");
      setTimeout(() => {
        setPreview(newFiles[0]);
      }, 100);
    } else {
      setPreview("");
    }
  };

  React.useEffect(() => {
    setPreview(props.pdfFile[0]);

    console.log("=============props.pdfFile=======================");
    console.log(props.pdfFile);
    console.log("=========props.pdfFile===========================");
    setFiles(props.pdfFile);
  }, [props.pdfFile]);

  let colorMode = {
    Grayscale: "luminosity",
    Color: "initial",
  };

  let pdfContainer = {
    width: "100%",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
    mixBlendMode: colorMode[color],
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-[#F5F5F5]">
          <div className="flex min-h-full items-end justify-center  text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden shadow-xl  transition-all">
                <div className="flex  items-end justify-center bg-[#F5F5F5] p-4 text-center sm:items-center sm:p-0">
                  <div
                    className="sm:flex-no-wrap flex min-w-full flex-col flex-wrap justify-between sm:flex-row"
                    style={{ minHeight: "100vh" }}
                  >
                    <div className="mx-1   basis-1/5 sm:bg-white ">
                      <div className="logo-container flex justify-center border-none py-5 sm:border-b-2 sm:py-10 ">
                        <img
                          className="w-[40%] sm:w-[60%]"
                          src={logo}
                          alt="PrintGo"
                        />
                      </div>
                      <div className="hidden sm:block">
                        <div className="list-file-container">
                          {!!files.length && (
                            <ul>
                              {files.map((file, key) => (
                                <ListItem
                                  file={file}
                                  preview={preview}
                                  setPreview={setPreview}
                                  setFiles={setFiles}
                                  key={key}
                                  removeFileList={removeFileList}
                                />
                              ))}
                            </ul>
                          )}
                        </div>
                        <DropLite
                          files={files}
                          setFiles={setFiles}
                          setPreview={setPreview}
                        />
                      </div>
                    </div>

                    <div className="mx-1  my-4  min-h-full w-full grow  basis-1/2 flex-col justify-center rounded-[20px] bg-white py-5 sm:min-h-[95%]">
                      <h3 className="pdf-name sm:text-md mb-2 text-sm">
                        {t("preview")} <br />{" "}
                        {!!preview.name && `${preview.name}`}
                      </h3>
                      <div className="preview-container flex min-h-[25rem] flex-1 justify-center  sm:min-h-[95%] ">
                        {!!preview && (
                          <embed
                            src={`${URL.createObjectURL(
                              preview
                            )}#toolbar=0&scrollbar=0&navpanes=0`}
                            style={pdfContainer}
                          />
                        )}
                      </div>
                    </div>

                    <div className="mx-1 block basis-1/5 rounded-[20px] bg-white sm:hidden">
                      <div className="list-file-container">
                        {!!files.length && (
                          <ul>
                            {files.map((file, key) => (
                              <ListItem
                                file={file}
                                preview={preview}
                                setPreview={setPreview}
                                setFiles={setFiles}
                                key={key}
                                removeFileList={removeFileList}
                              />
                            ))}
                          </ul>
                        )}
                      </div>
                      <DropLite
                        files={files}
                        setFiles={setFiles}
                        setPreview={setPreview}
                      />
                    </div>

                    <div className="mx-1  my-4 basis-1/4 flex-col justify-center rounded-[20px] bg-white py-5">
                      <PrintSettings
                        cancelCallback={props.setOpen}
                        nfiles={files.length}
                        setColor={setColor}
                        files={files}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

function PdfPreviewer() {
  const [open, setOpen] = React.useState(false);
  const [pdfFile, setPdfFile] = React.useState(null);
  const dropzoneRef = useRef(null);
  const buttonRef = useRef(null);
  const [store, dispatch] = React.useContext(StoreContext);
  const { t, i18n } = useTranslation();
  const [OpenRules, setOpenRules] = React.useState(false)
  const [OpenModal, setOpenModal] = React.useState(false)

  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    if (searchParams.size > 0 && searchParams.get("printerid")) {
      console.log(
        '============searchParams.get("printerid")========================'
      );
      console.log(searchParams.get("printerid"));
      console.log("====================================");
      dispatch({
        type: types.setPrinterId,
        payload: searchParams.get("printerid"),
      });
    }
  }, []);

  const onDrop = useCallback((acceptedFiles, rejectedfile) => {
    if (!!rejectedfile.length) {
      Swal.fire(
        `${t("error")}`,
        `${rejectedfile[0].errors[0].message}`,
        "warning"
      );
    }
    if (!!acceptedFiles.length) {
      const maxLength = 5;
      const reader = new FileReader();
      reader.onload = async () => {
        const buffer = reader.result;
        const loadingTask = pdfjs.getDocument(buffer);
        const pdf = await loadingTask.promise;
        console.log(pdf.numPages);
        if (pdf.numPages > 5) {
          Swal.fire(
            `${t("error")}`,
            `File must not exceed the limit of 5 pages`,
            "warning"
          );
          return;
        } else {
          setPdfFile(acceptedFiles);
          setOpen(true);
        }
      };
      reader.readAsArrayBuffer(acceptedFiles[0]);
    }
  }, []);

  const onDropRejected = (rejectedfile) => {
    console.log(rejectedfile);
    Swal.fire(`${t("error")}`, `${t("rejectedfile")}`, "warning");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    //onDropRejected,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  return (
    <div className="mt-5">
      <div {...getRootProps()} style={{}}>
        <input {...getInputProps()} />
        <div className="rounded-[20px] bg-white p-[20px]">
          <div className="border-sky-500 drop-container rounded-[13px] border-2 border-dashed bg-[#F5F5F5]">
            <div className="flex min-h-[40vh] min-w-[30vw] items-center justify-center ">
              <div className="flex flex-col items-center justify-center ">
                <img
                  className="my-5 h-[70px] w-[70px]"
                  alt="icon"
                  src={upload1}
                />
                <p className="title-dropdown-custom mb-1">
                  {isDragActive
                    ? "Suelta, lo estas apretando demasiado"
                    : t("presshereupload")}
                </p>
                <p className="my-5">{t("uploadpdf")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-5">
        <Storage />
      </div> */}
      {pdfFile && <Modal open={open} setOpen={setOpen} pdfFile={pdfFile} />}
      {/* Buttons outside the dropzone div for opening modals */}
      <div className="flex justify-center mt-4">
        <button
          className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
          onClick={() => setOpenRules(true)} // Replace with your modal opening logic for Modal 1
        >
          {t("helpbutton")}
        </button>
        {/* <button
          className="px-4 py-2 rounded-md bg-green-500 text-white hover:bg-green-700 ml-4"
          onClick={() => setOpenModal(true)} // Replace with your modal opening logic for Modal 2
        >
          Ads modal example
        </button> */}
      </div>
      {OpenRules && <RulesModal isOpen={OpenRules} setIsOpen={setOpenRules} adUrl="https://www.example.com" adContent="This is a great ad!"/>}
      {/* {OpenModal && <AdModal isOpen={OpenModal} setIsOpen={setOpenModal} adUrl="https://www.example.com" adContent="This is a great ad!"/>} */}
    </div>
  );
}

function DropLite(props) {
  const { t, i18n } = useTranslation();
  const onDrop = (acceptedFiles) => {
    props.setFiles([...props.files, ...acceptedFiles]);
    props.setPreview("");
    setTimeout(() => props.setPreview(acceptedFiles[0]), 100);
  };
  const onDropRejected = (acceptedFiles) => {
    Swal.fire(`${t("error")}`, `${t("rejectedfile")}`, "warning");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  return (
    <div className="mt-5">
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>
          {isDragActive
            ? "Suelta, lo estas apretando demasiado"
            : t("pressordrag")}
        </p>
      </div>
    </div>
  );
}

const dropzoneStyles = {
  border: "2px dashed #ccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

const Uploadview = () => {
  return <PdfPreviewer />;
};

export { PdfPreviewer };
export default Uploadview;
