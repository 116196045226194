import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import { routesFreePrint } from "routes.js";
import FavoriteDoc from "components/footer/Footer";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [currentRoute, setCurrentRoute] = React.useState("Inicio");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routesFreePrint);
  }, [location.pathname]);

  const getActiveRoute = (routesFreePrint) => {
    let activeRoute = "Inicio";
    for (let i = 0; i < routesFreePrint.length; i++) {

      if (!!routesFreePrint[i].subitem) {
        if (
          window.location.href.indexOf(routesFreePrint[i].layout + "/" + routesFreePrint[i].parent + "/" + routesFreePrint[i].path) !== -1
        ) {
          setCurrentRoute(routesFreePrint[i].name);
        }
      } else {
        if (
          window.location.href.indexOf(
            routesFreePrint[i].layout + "/" + routesFreePrint[i].path
          ) !== -1
        ) {
          setCurrentRoute(routesFreePrint[i].name);
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routesFreePrint) => {
    let activeNavbar = false;
    for (let i = 0; i < routesFreePrint.length; i++) {
      if (
        window.location.href.indexOf(routesFreePrint[i].layout + routesFreePrint[i].path) !== -1
      ) {
        return routesFreePrint[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getroutesFreePrint = (routesFreePrint) => {
    return routesFreePrint.map((prop, key) => {
      if (prop.layout === "/admin" || prop.layout === "/free-print" ) {
        if (!!prop.subitem) {
          let path = prop.path === "show" ? `/${prop.parent}/${prop.path}/:id` : `/${prop.parent}/${prop.path}`;
          return (
            <Route
              path={path}
              element={prop.component}
            />
          );
        } else {
          return (
            <Route path={`/${prop.path}`} element={prop.component} key={key} />
          );
        }
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[15vw]`}
        >
          {/* routesFreePrint */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"PrintGo | Imprime gratis y eco"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routesFreePrint)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getroutesFreePrint(routesFreePrint)}

                {/* {getroutesFreePrint(routesFreePrint)}

                <Route
                  path="/"
                  element={<Navigate to="/admin/dashboard" replace />}
                /> */}

                <Route
                  path="/"
                  element={<Navigate to="/admin/dashboard" replace />}
                />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
