import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import us from "assets/svg/us.svg";
import co from "assets/svg/co.svg"

const LanguageSwitchToggle = () => {
  const [isChecked, setIsChecked] = useState(false)
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const handleChange = (e) => {
    if(e.target.checked) {
      if(currentLanguage === 'es') {
        i18n.changeLanguage('en')
        return;
      }
        i18n.changeLanguage('es')
    } else {
        i18n.changeLanguage('en')
    } 
    setIsChecked(!isChecked)
}

  return (
    <>
      <label className='themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1'>
        <input
          type='checkbox'
          className='sr-only'
          checked={isChecked}
          onChange={handleChange}
        />
        <span
          className={`flex items-center space-x-[6px] rounded py-2 px-[13px] text-sm font-medium text-lg uppercase subpixel-antialiased ${
            !isChecked ? 'text-primary bg-[#f4f7ff]' : 'text-primary bg-[#f4f7ff]'
          }`}
        >
            {currentLanguage === "en" ? <img
                src={us}
                alt="US Flag"
                height="16"
                width="16" 
                className='mr-2'
                /> : <img
                src={co}
                alt="COL Flag"
                height="16"
                width="16" 
                className='mr-2'
            />}
          {currentLanguage}
        </span>

        {/* <span
          className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium text-lg uppercase subpixel-antialiased ${
            isChecked ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'
          }`}
        >
            
          {isChecked ? currentLanguage : ""}
        </span> */}
      </label>
    </>
  )
}

export default LanguageSwitchToggle;