import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import InputField from "components/fields/InputField";
import { useState } from 'react';

function Phoneinput(props) {
    // `value` will be the parsed phone number in E.164 format.
    // Example: "+12133734253".

    const {
        label,
        id,
        placeholder,
        onChange,
        value,
      } = props;
      //const [value, setValue] = useState()

    return (
      <PhoneInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        defaultCountry="CO"/>
    )
  }

  export default Phoneinput;